<template>
    <div :class="`notification notification--${type}`">
        <div class="notification__body">
            <div class="notification__icon">
                <AtomIcon :name="icon" width="18" />
            </div>
            <div class="notification__content">
                <div v-if="text" class="notification__text" v-text="text"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
    type: 'success' | 'warning' | 'error' | 'info',
    text?: string,
}>();

const icon = computed(() => {
    switch (props.type) {
    case 'success':
        return 'check';
    case 'warning':
        return 'alert-triangle';
    case 'error':
        return 'alert-octagon';
    case 'info':
        return 'bell';
    default:
        return '';
    }
});
</script>

<style lang="scss">
.notification {
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    border-radius: rem(5);

    @include helper-font-size(default);
    @include helper-color(white);
    @include helper-color-bg(white);

    &--success {
        background-color: $setting-color-notification-success;
    }

    &--warning {
        background-color: $setting-color-notification-warning;
    }

    &--error {
        background-color: $setting-color-notification-error;
    }

    &--info {
        background-color: $setting-color-notification-info;
    }

    &__body {
        display: flex;
    }

    &__icon {
        margin-top: rem(-1);
        padding-right: 0.675rem;
    }

    &__content {
        flex: 1;
    }
}
</style>
